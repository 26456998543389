@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    @apply text-base text-white bg-Mblack font-GilroyRegular
}

.container {
    max-width: 1300px;
}

.navMenu[aria-current="page"] {
    @apply bg-white text-Mblack
}

@media screen and (max-width: 1200px) {
    .container {
        max-width: 92%;
    }
}

section {
    padding: 80px 0;
}

.clamp-1 {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.clamp-3 {
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


/* start:btn */

.borderOrageRoundedGradient {
    border: 1px solid;
    border-image: linear-gradient(242.22deg, #FF5D2A 22.43%, rgba(255, 93, 42, 0) 67.25%) 1;
}

.borderOrageGradient {
    border: 1px solid;
    border-image: radial-gradient(25.16% 187.83% at 50% 50%, #FF7347 0%, rgba(255, 115, 71, 0) 100%) 1;
}

.btnClass {
    @apply px-[24px] py-[12px] rounded-full border border-solid leading-6 inline-block
}

.btnClass.large {
    @apply px-[24px] py-[16px]
}

/* end:btn */

.modal-backdrop.show {
    background-color: rgba(23, 23, 23, 0.08) !important;
    backdrop-filter: blur(100px);
}

.fieldInput {
    @apply bg-transparent h-[58px] px-4 rounded-full border border-solid border-[#E5E5E5] outline-none
}

.fieldInput:hover,
.fieldInput:focus,
.fieldInput:active {
    @apply bg-transparent h-[58px] px-4 rounded-full border border-solid border-[#E5E5E5] outline-none
}

.arrowPlus {
    @apply relative p-[0.3rem]
}

.arrowPlus span {
    transition: .5s;
    @apply inline-block w-full h-[1px] bg-Mblack absolute
}

.arrowPlus span:nth-child(1) {
    @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
}

.arrowPlus span:nth-child(2) {
    @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-[90deg]
}

.arrowPlus.active span {
    @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-[0]
}

.barIcon {
    @apply flex flex-wrap gap-1
}

.barIcon div {
    @apply w-full h-[2px] inline-block bg-white rounded-sm transition-all duration-500
}

.barIcon div {
    @apply w-full h-[2px] inline-block bg-white rounded-sm transition-all duration-500
}

.barIcon.active div:nth-child(1) {
    @apply absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 rotate-45
}

.barIcon.active div:nth-child(2) {
    @apply hidden
}

.barIcon.active div:nth-child(3) {
    @apply absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 -rotate-45
}

.menuMobile {
    @apply left-0 -top-[100vh] xl:hidden transition-all duration-500
}

.menuMobile.active {
    @apply top-0
}

.bgDropPopup {
    background: rgba(23, 23, 23, 0.08);
    backdrop-filter: blur(4px);
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}


.sliderDots .slick-dots li button::before {
    @apply text-[8px] !text-[#E5E5E5]
}

.sliderDots .slick-dots li.slick-active button::before {
    @apply text-[12px] !text-Morange
}

.sliderDots .slick-dots {
    @apply -bottom-[3rem]
}

.modalTicket .modal-dialog .modal-content {
    @apply border-none bg-transparent
}

.modalTicket .modal-dialog .modal-content .modal-body {
    @apply bg-white rounded-[24px] !p-[2rem] overflow-hidden
}

.stepForm .items {
    @apply relative
}

.stepForm .items::after {
    content: "";
    @apply absolute w-full md:w-[1px] h-[1px] md:h-full bg-[#E5E5E5] left-[5rem] md:left-[1.3rem] top-[1.4rem] md:top-auto md:-bottom-[3rem]
}

.stepForm .items:last-child::after {
    content: "";
    @apply !bg-white
}

.stepForm .items.active::after {
    @apply bg-Morange
}

.stepForm .items.active .number {
    @apply text-Morange !bg-[#F3FCF9] !border-Morange
}

.phoneModFiled input.form-control {
    @apply hidden
}

.phoneModFiled .flag-dropdown .selected-flag .flag .arrow {
    @apply hidden
}

.phoneModFiled .flag-dropdown {
    @apply !bg-transparent !border-hidden !rounded-none
}

.paginationnCustom {
    @apply gap-2
}

.paginationnCustom li a {
    @apply text-Mblack border border-solid !border-[#E5E5E5] focus:bg-white focus:shadow-none !rounded-full w-[38px] h-[38px]
}

.paginationnCustom li span {
    @apply w-[38px] h-[38px]
}

.paginationnCustom li {
    @apply flex-shrink-0
}

.paginationnCustom li.active span {
    @apply !bg-Morange !border-Morange
}

.paginationnCustom li span {
    @apply rounded-full
}

/* verificaion */
.wrapVerification .vi__wrapper {
    @apply w-full
}

.wrapVerification .vi__wrapper .wrap {
    @apply flex items-center justify-between w-full gap-4
}

.wrapVerification .vi__wrapper .wrap .character {
    flex-basis: auto;
    flex-grow: unset;
    @apply w-[56px] h-[56px] rounded-full
}

.wrapVerification .vi__wrapper .wrap .character {
    @apply font-bold text-[24px] text-Mblack
}

.wrapVerification .vi__wrapper .wrap .character:where(.vi__character--inactive) {
    @apply bg-[#FAFAFA] border-[#F5F5F5]
}

.wrapVerification .vi__wrapper .wrap .character:where(.vi__character) {
    @apply bg-[#FAFAFA] border-[#F5F5F5]
}

.wrapVerification .vi__wrapper .wrap .character:where(.vi__character--selected) {
    outline: 2px solid #793EF1;
    @apply text-Morange
}

/* verificaion */

/* aniamtion  */

.circleCase .logo2 {
    display: none;
}

.circleCase:hover .logo1 {
    display: none;
}

.circleCase:hover .logo2 {
    display: block;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.wrapCircle:hover p {
    @apply text-white
}

.wrapCircle:hover a {
    @apply bg-[#FFFFFF1A]
}

.wrapCircle:hover>div {
    @apply text-white
}

.cardCaseStudy div h2, .cardCaseStudy div div p, .cardCaseStudy div div h4 {
    @apply text-Mblack transition-all duration-300
}
.cardCaseStudy:hover div h2, .cardCaseStudy:hover div div p, .cardCaseStudy:hover div div h4 {
    @apply text-white
}



/* .circleHover:hover img {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
} */

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* aniamtion  */

/* calendry */

/* calendry */